<template>
  <v-card elevation="0" class="border-0" tile flat>
    <v-card-title class="px-0 pb-0">{{ title }}</v-card-title>
    <div class="my-2" v-if="loading">
      <v-row dense>
        <v-col :cols="colSize">
          <v-skeleton-loader
            class="mx-auto"
            type="card"
            tile
          ></v-skeleton-loader>
        </v-col>
        <v-col :cols="colSize">
          <v-skeleton-loader
            class="mx-auto"
            type="card"
            tile
          ></v-skeleton-loader>
        </v-col>
        <v-col :cols="colSize">
          <v-skeleton-loader
            class="mx-auto"
            type="card"
            tile
          ></v-skeleton-loader>
        </v-col>
        <v-col :cols="colSize">
          <v-skeleton-loader
            class="mx-auto"
            type="card"
            tile
          ></v-skeleton-loader>
        </v-col>
        <v-col :cols="colSize">
          <v-skeleton-loader
            class="mx-auto"
            type="card"
            tile
          ></v-skeleton-loader>
        </v-col>
        <v-col :cols="colSize">
          <v-skeleton-loader
            class="mx-auto"
            type="card"
            tile
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </div>

    <div class="my-2">
      <v-row dense>
        <v-col v-for="(book, i) in books" :key="i" :cols="colSize">
          <BookBox :data="book"></BookBox>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
import BookBox from "@/components/BookBox.vue";

export default {
  components: {
    BookBox,
  },

  data() {
    return {
      title: "Terakhir di Update",
      loading: null,
      books: [],
    };
  },

  computed: {
    colSize() {
      // console.log(this.$vuetify.breakpoint.name);
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 6;
        case "sm":
          return 6;
        case "md":
          return 4;
        case "lg":
          return 3;
        case "xl":
          return 2;
      }
      return 12;
    },
  },

  methods: {
    async getList() {
      try {
        this.loading = true;
        await this.$axios.get("elibrary/new").then((res) => {
          this.loading = false;
          if (res.data.status == "success") {
            this.books = res.data.data.books;
          } else {
            this.books = [];
          }
        });
      } catch (error) {
        this.loading = false;
        this.setAlert("error", error);
      }
    },
  },

  mounted() {
    this.getList();
  },
};
</script>


<style scoped>
.textsmall {
  font-size: 8pt !important;
}
</style>